var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"form",staticClass:"checkout main",attrs:{"model":_vm.form,"label-position":"left","label-width":"auto"}},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"row"},[_c('GoodsItem',{attrs:{"data":{},"isHeader":true}}),_vm._l((_vm.cart),function(item,i){return _c('div',{key:i},[(_vm.Common.isExist(item.Optd))?_c('GoodsItem',{attrs:{"data":item.Optd}}):_vm._e(),(_vm.Common.isExist(item.Addition))?_vm._l((item.Addition),function(addi,index){return _c('GoodsItem',{key:index,attrs:{"data":addi}})}):_vm._e(),(_vm.Common.isExist(item.Access))?_vm._l((item.Access),function(acc,index){return _c('GoodsItem',{key:index,attrs:{"data":acc}})}):_vm._e()],2)}),(_vm.Common.isExist(_vm.giftList))?_vm._l((_vm.giftList),function(gift){return _c('GoodsItem',{directives:[{name:"show",rawName:"v-show",value:(gift.isShow),expression:"gift.isShow"}],key:gift.skuId,attrs:{"data":gift}})}):_vm._e()],2),_c('div',{staticClass:"row coupon"},[(_vm.Common.isExist(_vm.couponList1) || _vm.Common.isExist(_vm.couponList2))?_c('div',{staticClass:"coupon-item discount"},[_c('div',{staticClass:"row-label"},[_vm._v("折扣券")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.cpnMsg))]),_c('span',{staticClass:"opendisc",on:{"click":function($event){_vm.$refs.coupon.couponVisible = true}}},[_vm._v("檢視優惠明細")])]),_c('Coupon',{ref:"coupon",attrs:{"source":"checkout","navList":[
						{ name: '可用', list: _vm.couponList1 },
						{ name: '不可用', list: _vm.couponList2 } ]},on:{"disChange":_vm.disChange}})],1):_vm._e(),_c('div',{staticClass:"coupon-item"},[_c('div',{staticClass:"row-label"},[_vm._v("優惠碼")]),_c('div',[_c('el-input',{attrs:{"placeholder":"(選填)"},on:{"blur":_vm.onExchange},model:{value:(_vm.promoCode),callback:function ($$v) {_vm.promoCode=$$v},expression:"promoCode"}}),(_vm.amtPromo)?_c('div',{staticClass:"amtPromoTips"},[_vm._v(" 優惠金額 "),_c('i',[_vm._v(_vm._s(_vm._f("priceFormat")(_vm.amtPromo)))])]):_vm._e()],1)])]),_c('div',{staticClass:"row flexrow",staticStyle:{"align-items":"inherit"}},[_c('div',{staticClass:"row-form"},[_c('Inv',{attrs:{"inv":_vm.inv},on:{"getInvForm":function (form) { return (this$1.invForm = form); }}}),_c('el-form-item',{attrs:{"prop":"message","rules":_vm.messageRule,"label":"留言備註","label-width":"80px"}},[_c('el-input',{attrs:{"placeholder":_vm.config.setting.markPlaceHolder},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('CheckOutList',{attrs:{"data":{
					amtTtl: _vm.amtTtl,
					discountPrice: _vm.discountPrice,
					amtCpgDisc: _vm.amtCpgDisc,
					amtPromo: _vm.amtPromo,
					amtTtl2: _vm.amtTtl2,
				}}})],1),(_vm.Common.isExist(_vm.checkPtBuyInfo.text))?_c('el-alert',{staticClass:"checkPt-tips",attrs:{"type":"warning","closable":false}},[(_vm.checkPtBuyInfo.soNbr)?_c('div',{staticClass:"is-pointer",on:{"click":function($event){return _vm.$router.push(("/order/detail/" + (_vm.checkPtBuyInfo.soNbr)))}}},[_vm._v(_vm._s(_vm.checkPtBuyInfo.text))]):_c('div',[_vm._v(_vm._s(_vm.checkPtBuyInfo.text))])]):_vm._e(),(_vm.checkPtBuyInfo.stat == 'available')?_c('div',{staticClass:"row checkout-foot"},[_c('div'),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitbar},on:{"click":_vm.onCheckoutSubmit}},[_vm._v("立即下單")])],1):_vm._e(),_c('CouponGet',{ref:"couponGet",attrs:{"cpnlist":_vm.unCpnlist}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }