//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, FormItem, Image, Input, Radio, RadioGroup, Tag, Button, Message, Alert } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ctrlCart, ctrlCpn, ctrlInv, ctrlOrder, ctrlBase, ctrlPt } from "@/controller";
import { Address, GoodsItem, Coupon, CheckOutList, CouponGet, Inv } from "@/components";
import BaseRadioGroup from "@/components/Base/BaseRadioGroup/BaseRadioGroup.vue";
export default {
	components: {
		[Form.name]: Form,
		[FormItem.name]: FormItem,
		[Image.name]: Image,
		[Tag.name]: Tag,
		[Input.name]: Input,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Button.name]: Button,
		[Alert.name]: Alert,
		Address,
		Coupon,
		GoodsItem,
		BaseRadioGroup,
		CheckOutList,
		CouponGet,
		Inv,
	},
	data() {
		return {
			cart: [], //購物車數據
			couponList1: [], //可用優惠券
			chosenCpn: [], //已勾選優惠券
			couponNbr: [], //選中優惠券Nbr集合
			couponList2: [], //不可用優惠券
			sceneAvail: {}, //領券規則
			promoCode: null, //優惠碼
			cpnTab: "", //優惠券切換
			cpnPop: false, //優惠券彈窗

			

			amtTtl: 0, //總價
			discountPrice: 0, //折扣金額
			amtPromo: 0, //優惠碼折減金額
			amtCpgDisc: 0, //優惠金額

			consent: true, //同意服務條款
			submitbar: false, //提交延遲鎖
			sourceType: 0, //來源類型 1：直接購買 2：購物車 3：團購
			unCpnlist: [], //無門檻領券列表
			giftList: [], //贈品列表
			adrRadioLoading: false,
			ctrlBase,
			isCoupon: false,
			cpnList: [], //商品頁帶入優惠券列表
			checkPtBuyInfo: {
				stat: "available",
			}, //檢測課程是否可買
			form: {
                message: "", //留言
            },

			inv: {
				name: "",
				email: "",
				invType: 2,
				carrierType: 0,
				carrierID: "",
				invTitle: "",
				taxNo: "",
				loveCode: "",
			},
			invForm: "",
		};
	},
	computed: {
		...mapState({
			config(state) {
				return state.base.configInfo;
			},
			//validateMsg
			msg(state) {
				return state["validate"];
			},
			userInfo: function(state) {
				const userInfo = state.user.userInfo;
				return userInfo;
			},
            messageRule() {
				let rule = [];
				if (this.config.setting.isMark) {
					rule = [
						{
							trigger: "blur",
							required: true,
							message: this.msg.req,
						},
					];
				}
				return rule;
			},
		}),
		//可用優惠券說明文字
		cpnMsg() {
			let msg = "暫無可用優惠";
			if (this.Common.isExist(this.discountPrice)) {
				msg = "已優惠" + this.discountPrice + "元";
			} else if (this.giftList.length > 0 && this.isCoupon) {
				msg = "已使用贈品";
			} else if (this.couponList1.length > 0) {
				msg = this.couponList1.length + "張可用";
			}

			return msg;
		},
		//減去優惠後的總價
		amtTtl2() {
			let ttl = Number(this.amtTtl) - Number(this.discountPrice) - Number(this.amtPromo) - Number(this.amtCpgDisc);
			return ttl <= 0 ? 0 : ttl;
		},
	},
	watch: {
		userInfo: {
			handler: function(newval) {
				this.getUserInfo();
			},
			immediate: true,
		},
	},
	created() {
		this.getInit();

		window.getStoreAddrInfo = this.getStoreAddrInfo;
	},
	mounted() {},
	methods: {
		async getInit() {
			await this.getCart();
			this.checkPtBuy();
			// this.getInvList();
		},
		//檢測商品是否可買
		async checkPtBuy() {
			this.checkPtBuyInfo = await ctrlPt.checkPtBuy({ ptNbr: this.cart[0].ptNbr });
		},
		//獲取購物車數據
		async getCart() {
			const data = await ctrlCart.getBuyNow();
			if (data) {
				this.cart = data.PtList;
				this.sourceType = data.sourceType;
				this.cpnList = data.CpnList;
				console.log("購物車數據");
				console.log(this.cart);

				// 排序
				for (const item of this.cart) {
					item.cpgNbr ? (item.sort = true) : (item.sort = false);
				}
				this.cart.sort((a, b) => b.sort - a.sort);
				await this.uncollectCoupon();
				this.getCheckoutResult();
			}
		},
		//獲取結算結果
		async getCheckoutResult() {
			this.checkRet = await ctrlCart.getCheckoutResultByCheck(this.cart);
			if (this.Common.isExist(this.checkRet)) {
				const realList = this.checkRet.PriceList;
				//默認贈品全顯示
				for (const gift of this.checkRet.GiftList) {
					gift.isShow = true;
				}
				this.giftList = this.checkRet.GiftList;
				//匹配商品列表 替換價格
				for (const cart of this.cart) {
					for (const real of realList) {
						if (real.skuId) {
							//!cart.ptNbr.includes("p-") 防止套裝與商品 skuId一致引起的價格覆蓋問題
							if (cart.Optd.skuId == real.skuId && !cart.ptNbr.includes("p-")) {
								cart.Optd.price = real.price;
							}
						}
						if (real.ptNbr) {
							if (cart.ptNbr == real.ptNbr) {
								cart.Optd.price = real.price;
							}
						}

						if (this.Common.isExist(cart.Addition)) {
							for (const addi of cart.Addition) {
								if (addi.skuId == real.skuId) {
									addi.price = real.price;
								}
							}
						}
					}
				}

				//初始化優惠信息
				this.amtTtl = this.checkRet.amtTtl;
				this.discountPrice = this.checkRet.amtDisc;
				this.amtCpgDisc = this.checkRet.amtCpgDisc;

				this.couponNbr = this.checkRet.AvailCouponList.map((item) => item.couponNbr);
				const UnAvailCouponNbrs = this.checkRet.UnAvailCouponNbrs;

				setTimeout(async () => {
					[this.couponList1, this.couponList2] = await ctrlCpn.getCouponByCouponNbr({ CouponNbrs: this.couponNbr, UnAvailCouponNbrs });
					//找出贈品優惠券
					for (const coupon of this.couponList1) {
						for (const availCoupon of this.checkRet.AvailCouponList) {
							if (availCoupon.couponNbr == coupon.couponNbr) {
								coupon.skuId = availCoupon.giftSkuId;
								this.isCoupon = true;
							}
						}
					}
					this.disChange;
				}, 500);
			}
		},
		//無門檻領券
		async uncollectCoupon() {
			// 沒有存在優惠券列表 return
			if (!this.Common.isExist(this.cpnList)) return;

			const cpnNbrs = this.cpnList.map((item) => item.cpnNbr);
			const unCpnList = await ctrlCpn.getCouponByCpn({ cpnNbrs: cpnNbrs.toString() });

			const unCpnNbrs = unCpnList.filter((item) => item.stat == -1).map((ele) => ele.cpnNbr);
			//沒有未領取的無門檻券 直接return
			if (!this.Common.isExist(unCpnNbrs)) return false;

			const data = await ctrlCpn.collectCoupon({ cpnNbrs: unCpnNbrs.toString(), scene: 0 });
			if (data) {
				this.unCpnlist = data;
				this.$refs.couponGet.couponShow = true;
			}
		},
		//優惠券彈窗 優惠改變
		disChange(item, curState, curItem) {
			console.log("優惠券改變");
			//item //已勾選優惠券
			for (const gift of this.giftList) {
				if (gift.skuId == curItem?.skuId) {
					this.isCoupon = curState;
					gift.isShow = curState;
				}
			}

			this.discountPrice = 0;
			// this.giftList = [];
			//篩選勾選優惠券的couponNbr
			this.couponNbr = item.map((item) => item.couponNbr);
			const availCouponList = this.checkRet.AvailCouponList;
			for (const chosenCpn of item) {
				// item.isShow = true
				for (const availCoupon of availCouponList) {
					// !availCoupon.giftSkuId 非贈品優惠券
					if (!availCoupon.giftSkuId) {
						if (chosenCpn.couponNbr == availCoupon.couponNbr) {
							if (availCoupon.amtDisc) this.discountPrice += availCoupon.amtDisc;
						}
					}
				}
			}
		},
		//結帳表單提交
		async onCheckoutSubmit() {
			console.log("addOrder");
			console.log(this.invForm);
			this.invForm.validate((valid) => {
				if (!valid) {
					console.log("error submit!!");
					return false;
				}

				this.submitbar = true;
				setTimeout(() => {
					this.submitbar = false;
				}, 1500);
				let order = {
					PtList: [],
					CartList: [],
					amtTtl: this.amtTtl,
					amtDisc: this.discountPrice + this.amtCpgDisc,
					amtPromo: this.amtPromo,
					promoCode: this.promoCode,
					couponNbr: this.couponNbr.toString(),
					invType: this.inv.invType,
					invEmail: this.inv.email,
					memEmail: this.inv.email,
					message: this.form.message,
					receiverName: this.inv.invType == 2 ? this.inv.name : this.inv.invTitle,
				};
				if (this.inv.invType == 2) {
					if (this.inv.carrierType) order = { ...order, carrierType: this.inv.carrierType };
					if (this.inv.carrierID) order = { ...order, carrierID: this.inv.carrierID };
					if (this.inv.loveCode) order = { ...order, loveCode: this.inv.loveCode };
					if (this.inv.name) order = { ...order, invTitle: this.inv.name };
					// if (this.inv.name) order = { ...order, receiverName: this.inv.name };
				} else if (this.inv.invType == 3) {
					if (this.inv.invTitle) order = { ...order, invTitle: this.inv.invTitle };
					if (this.inv.taxNo) order = { ...order, taxNo: this.inv.taxNo };
                    // if (this.inv.name) order = { ...order, invAddr: this.inv.name };
				}
				this.addOrder(order);
			});
		},
		//立即購買加入訂單
		async addOrder(order) {
			ctrlBase.gaSentEvent("結算頁", "提交購買", ctrlBase.getPtNameStr(this.cart, "Optd", "ptName"));
			const cart = this.cart[0];
			if (cart.cpgNbr) order.cpgNbr = cart.cpgNbr;

			order.PtList = [
				{
					ptNbr: cart.ptNbr,
					cpgNbr: cart.cpgNbr || null,
					skuId: cart.Optd.skuId,
					qtyOrd: cart.Optd.qtyOrd,
					price: cart.Optd.price,
					kind: 0,
				},
			];
			let obj = {};
			for (const item of cart.Addition) {
				obj = {
					cpgNbr: cart.cpgNbr || null,
					skuId: item.skuId,
					qtyOrd: item.qtyOrd,
					price: item.price,
					kind: 2,
					parPtNbr: cart.ptNbr,
				};
				order.PtList.push(obj);
			}

			await ctrlOrder.addOrder(order);
		},
		//優惠碼兌換
		async onExchange() {
			ctrlBase.gaSentEvent("結算頁", "優惠碼兌換", ctrlBase.getPtNameStr(this.cart, "Optd", "ptName"));
			let ptNbrs = this.cart.map((item) => item.ptNbr);
			if (this.promoCode) {
				this.amtPromo = await ctrlCpn.getPromoAmt({ promoCode: this.promoCode, ptNbrs });
			}
		},
		getUserInfo() {
			this.inv.email = this.userInfo.email;
		},
	},
};
